.field {
    display: flex;
    align-items: center;

    width: 100%;

    max-width: 300px;

    & > div {
        width: 100%;
    }

    &:not(:last-of-type) {
        margin-bottom: 15px;
    }
}

@import 'shared/assets/styles/media';

.extraSpace {
    width: 100%;
    min-height: 64px;
    max-height: 64px;
    height: 64px;
}

.footer {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% - 255px);
    border-top: 1px solid #e8e8e8;
    margin-top: 5px;
    padding: 10px 20px;
    min-height: 64px;
    background-color: #fff;

    @media screen and (max-width: $whenSmallScreen) {
        width: 100%;
    }
}

.actions {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.action {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-of-type) {
        margin-right: 10px;
    }
}

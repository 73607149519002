.columnsButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        min-height: 19px;
        max-height: 19px;
        min-width: 19px;
        max-width: 19px;
    }
}

@import 'shared/assets/styles/media';

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.modal {
    display: flex;
    width: 100%;
}

.modalMask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100500;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.52);

    animation: fadein 400ms;
}

.modalWrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100500;
    padding: 10px;
    overflow: auto;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 500ms;
}

.overflow {
    overflow: hidden;
}

.sideMenu {
    width: 255px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    overflow: hidden;

    & + .modalContent {
        width: calc(100% - 255px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    @media screen and (max-width: $whenSmallScreen) {
        width: 90px;

        & + .modalContent {
            width: calc(100% - 90px);
        }
    }
}

.modalContent {
    position: relative;
    width: 100%;
    padding: 0 20px;
    border-radius: 6px;
    box-shadow: 0 11px 29px 0 rgba(0, 0, 0, 0.31);
    background-color: #ffffff;
}

.modalButton {
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 0;
    background-color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    z-index: 100;
}

.modalHeader {
    padding-top: 16px;
    margin-bottom: 0;
    font-size: 20px;
    line-height: normal;
    font-weight: 600;
    color: #2c2c2c;
}

.modalBody {
    padding: 20px 0;
}

.modalFooter {
    margin-top: 10px;
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #e8e8e8;
}

.stats,
.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stats {
    flex-wrap: wrap;
}

.card {
    min-width: 270px;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 20px 18px -17px rgba(0, 0, 0, 0.29);
    border: solid 1px #d9d9d9;
    margin-bottom: 20px;
}

.title,
.value {
    line-height: normal;
    color: #2c2c2c;
}

.title {
    font-size: 14px;
}

.value {
    font-size: 16px;
}

@media screen and (max-width: 1110px) {
    .card {
        width: 100%;
    }
}

.row {
    display: flex;
    align-items: center;

    .title {
        color: #777;
        margin-right: 5px;
    }
}

.grid {
    display: flex;
    flex-direction: column;

    .row:not(:last-of-type) {
        margin-bottom: 15px;
    }
}

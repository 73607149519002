.list {
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 5px;
}

.content {
}

.footer {
    padding: 15px 0;
}

.removableField {
    display: flex;
    align-items: flex-end;
    max-width: 100%;
}

.remove {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 14px;
    margin-right: 5px;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;

    border: none;
    background-color: transparent;

    transition: transform 200ms;
    width: 25px;
    min-width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0;

    transition: background-color 200ms;

    &:hover,
    &:focus,
    &:active {
        &:not(:disabled) {
            background-color: #e7edf3;
        }
    }

    svg {
        width: 18px;
        height: 18px;
    }
}
.isLoading {
    animation: loading 1.5s infinite linear;
}

.red svg path {
    fill: #da412f;
}

.blue svg path {
    fill: #176bbc;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

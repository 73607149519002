@import 'shared/assets/styles/media';

.closeMenu {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    height: 63px;
    display: none;
    margin-left: 10px;
    margin-right: 5px;

    @media screen and (max-width: $whenSmallScreen) {
        display: flex;
        align-items: center;
    }

    &:before {
        display: inline-block;
        margin-right: 3px;
        content: '';
        width: 1px;
        height: 14px;
        background-color: rgba(216, 216, 216, 0.6);
    }
}

.closeButton {
    svg g {
        fill: #fff;
    }

    &:hover,
    &:focus,
    &:active {
        svg g {
            fill: #205e98;
        }
    }
}

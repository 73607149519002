.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e7edf3;
    font-size: 16px;

    svg {
        fill: currentColor;
    }
}

.closeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 3px 0;
    outline: none;
    border: none;
    background: transparent;
    height: 10px;

    cursor: pointer;
}

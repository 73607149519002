.list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
}

.item:not(:last-of-type) {
    margin-bottom: 7px;
}

.label {
    display: flex;
    cursor: pointer;
}

.title {
    display: inline-flex;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title {
    font-size: 80px;
    font-weight: bold;
    line-height: normal;
}

.subTitle {
    font-weight: 600;
    line-height: 1.43;
}

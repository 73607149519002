@import 'shared/assets/styles/media';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 5px;
    padding: 10px 20px;

    @media screen and (max-width: $whenSmallScreen) {
        padding-left: 57px;
    }
}

.title {
    font-size: 20px;
    padding: 8px 0 7px;
    margin: 0;
    color: #2c2c2c;
    line-height: normal;
}

.actions {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.action {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-of-type) {
        margin-right: 10px;
    }
}

.toast {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 10px;
}

@import 'shared/assets/styles/media';

.layout {
    padding-left: 255px;
    background-color: #fff;

    @media screen and (max-width: $whenSmallScreen) {
        padding-left: 0;
    }
}

.divider {
    width: 100%;
    min-height: 1px;
    max-height: 1px;

    background-color: #205e98;
}

.body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

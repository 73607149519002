.resendWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    button {
        font-size: 12px;

        &[disabled]{
            cursor: not-allowed;
        }
    }
}

.expandable {
    flex-grow: 1;
}

.resendTimer {
    margin-left: 5px;
    opacity: 0.4;
}

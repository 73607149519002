.blueCircle {
    min-width: 17px;
    max-width: 17px;
    min-height: 16px;
    max-height: 16px;
    border-radius: 50%;
    background-color: rgba(0, 148, 254, 1);
    margin-right: 5px;
}

.legend {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #595959;
    font-size: 16px;
}

.callsNumber,
.total {
    display: flex;
    align-items: center;
}

.callsNumber {
    margin-right: 5px;
}

.form {
    width: 300px;
    background-color: #fff;
    padding: 20px;
    box-sizing: content-box;
    border-radius: 6px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.field {
    margin-bottom: 15px;
}

.noResults {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    flex-grow: 1;
    width: 100%;

    font-size: 24px;

    height: 100%;
    min-height: 400px;
}

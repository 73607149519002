@import '~antd/dist/antd.css';
@import '~@didww/ui-kit/build/index.css';

@font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 600;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
        url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url('../fonts/OpenSans-Bold.ttf') format('truetype');
}

html {
    height: 100%;
    overflow-y: scroll;
}

body {
    color: #595959;
    min-height: 100vh;
    font-family: OpenSans;
    font-size: 14px;
}

.pannel-tooltip {
    z-index: 100500;
}

body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

body::-webkit-scrollbar-track {
    background: #e8e8e8;
    border-radius: 2.5px;
}

body::-webkit-scrollbar-thumb {
    background: #94b6d6;
    border-radius: 2.5px;
}

#root {
    min-height: 100vh;
    height: 100%;
}

a {
    text-decoration: none;
    outline: none;
    background-color: transparent;
    transition: color 0.2s;
    color: #176bbc;
}

a:hover,
a:focus {
    color: #6ba2d6;
}

a:active {
    color: #1260ac;
}

h1,
h2,
h3,
h4,
b {
    font-weight: 600;
}

.title {
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
}

.subTitle {
    opacity: 0.5;
    line-height: 1.43;
}

@import 'shared/assets/styles/media';

.title {
    font-size: 60px;
    font-weight: bold;
    line-height: normal;

    @media screen and (max-width: $whenExtraSmallScreen) {
        font-size: 40px;
    }
}

.subTitle {
    font-weight: 600;
    line-height: 1.43;
}

.filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.fields {
    display: flex;
    flex-wrap: wrap;
}

.actions {
    margin-top: 26px;
}

.item {
    padding-right: 10px;
    padding-bottom: 10px;
    max-width: 100%;

    &.small {
        width: 120px;
    }

    &.medium {
        width: 200px;
    }

    &.large {
        width: 350px;
    }
}

.toggle {
    display: flex;
    align-items: center;
}

.label {
    color: #777;
    margin-left: 5px;
    cursor: pointer;
}

.wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 0 20px;
    text-align: center;
    overflow: hidden;
    background-color: #023f7f;
}

.btnWrapper {
    a,
    button {
        max-width: 177px;
        min-width: 177px;
        width: 100%;
    }
}

.Toastify__toast-container--top-right {
    top: 11px;
    right: 20px;
    padding: 0;
    width: 334px;
    z-index: 999999;
}

.Toastify__toast {
    min-height: 40px;
    padding: 12px 10px;
    margin-bottom: 10px;
    border-radius: 6px;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}

.Toastify__toast-body {
    font-family: OpenSans;
    font-size: 12px;
    line-height: normal;
    padding: 0;
}

.Toastify__toast .Toastify__toast-body a {
    color: #fff !important;
    text-decoration: underline !important;
}

.Toastify__toast--success {
    box-shadow: 0 11px 15px -9px #7cb947;
    background: rgba(124, 185, 71, 0.9);
}

.Toastify__toast--error {
    box-shadow: 0 11px 15px -9px #ef6656;
    background: rgba(239, 102, 86, 0.9);
}

.Toastify__toast--warning {
    box-shadow: 0 11px 15px -9px #e3a631;
    background: rgba(255, 178, 24, 0.9);
}

.Toastify__toast--info {
    box-shadow: 0 11px 15px -9px #176bbc;
    background: rgba(23, 107, 188, 0.9);
}

@import 'shared/assets/styles/media';

.spinner {
    align-self: center;
    position: fixed;
    top: 50vh;
    text-align: center;
    background-color: #fff;
    width: calc(100% - 255px);

    @media screen and (max-width: $whenSmallScreen) {
        width: 100%;
    }
}

.divider {
    width: 100%;
    border-bottom: 1px solid #f1f1f1;
}

.vertical {
    margin: 0 12px;
    height: 14px;
    width: 1px;
    background-color: #f1f1f1;
    border: none;
}

.title {
    font-size: 80px;
    font-weight: bold;
    line-height: normal;
}

.subTitle {
    font-weight: 600;
    line-height: 1.43;
}

.footer {
    max-width: 570px;
    width: 100%;
    line-height: 1.67;
    font-size: 12px;
}

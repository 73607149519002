.subTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #2c2c2c;
    margin-bottom: 20px;
}

.subSection {
    max-width: 1100px;

    &:not(:last-of-type) {
        margin-bottom: 40px;
    }
}

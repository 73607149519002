@import 'shared/assets/styles/variables';

.react-toggle {
    height: 32px;
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    background-color: transparent;
    border: 0;
    padding: 0;
    user-select: none;
    cursor: pointer;

    .react-toggle-track {
        height: 32px;
        width: 52px;
        border-radius: 16px;
        background-color: #1260ac;
        padding: 0;
        transition: all 0.2s ease;
        box-shadow: 0 11px 15px -9px #176bbc;
    }

    .react-toggle-thumb {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 24px;
        height: 24px;
        border: 0;
        border-radius: 50%;
        background-color: #ffffff;
        box-sizing: border-box;
        transition: all 0.25s ease;
    }

    &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #6ba2d6;
    }

    &__wrapper {
        position: relative;
        display: flex;

        .react-toggle__spinner {
            position: absolute;
            left: 6px;
            top: 6px;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            z-index: 20;

            color: #176bbc;
            opacity: 0.5;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &--checked .react-toggle__spinner {
            left: 26px;
            color: $green;
        }
    }

    &--focus .react-toggle-thumb {
        box-shadow: none;
    }

    &--checked {
        &:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: #a1d177;
        }

        .react-toggle-track {
            background-color: $green;
            box-shadow: 0 11px 15px -9px $green;
        }

        .react-toggle-thumb {
            left: 24px;
            border-color: #19ab27;
        }
    }

    &__wrapper--color-orange {
        &:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: lighten($orange, 10%);
        }

        .react-toggle-track {
            background-color: $orange;
            box-shadow: 0 11px 15px -9px $orange;
        }

        .react-toggle-thumb {
            left: 24px;
            border-color: darken($orange, 10%);
        }
    }

    &--disabled {
        cursor: not-allowed;
        opacity: 0.5;
        transition: opacity 0.25s;

        .react-toggle-track {
            box-shadow: none;
        }
    }
}

.react-toggle-screenreader-only {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    opacity: 0;
}

.tooltip {
  display: flex;
  flex-direction: column;
}

.title {
  color: #595959;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 14px;
}

.rules {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0 0 8px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    span {
      margin-left: 10px;
    }
  }
}

.progress {
  display: flex;
  height: 3px;
  background-color: #f3f2f2;
  width: 100%;
}

.progressInner {
  width: 1px;
  border-radius: 1px;
  height: 3px;
}

.message {
  line-height: 1.57;
}
.bg {
  width: 100%;
  height: 100%
}

.enter {
  opacity: 0;
}

.enterActive {
  transition: opacity 300ms;
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  transition: opacity 300ms;
  opacity: 0;
}
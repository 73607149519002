@import 'shared/assets/styles/media';

.hamburger {
    position: fixed;
    left: 13px;
    top: 13px;
    display: none;
    z-index: -1;

    @media screen and (max-width: $whenSmallScreen) {
        z-index: 2;
        display: inline-block;
    }
}

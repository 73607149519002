.card {
    border-radius: 6px;
    box-shadow: 0 20px 18px -17px rgba(0, 0, 0, 0.29);
    border: solid 1px #d9d9d9;
}

.table {
    padding: 6px 10px 0;
    min-height: 400px;
}

.row {
    cursor: pointer;

    &.selected {
        background-color: #e7f0f8;
    }

    &:hover {
        background-color: rgba(23, 107, 188, 0.05);
    }
}

.footer {
    padding: 0 10px;

    &Content {
        min-height: 72px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 17px;
        border-top: 1px solid #e8e8e8;

        :global(.c-pagination-wrap) {
            padding-top: 0;
            justify-content: center;
        }

        .count,
        .button {
            margin-top: 7px;
        }
    }
}

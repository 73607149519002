.title {
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    margin: 0 -10px;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border: none;
        padding-bottom: 0;
    }
}

@import 'shared/assets/styles/media';

.formFooter {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 255px);
    border-top: 1px solid #e8e8e8;
    margin-top: 5px;
    padding: 10px 20px;
    min-height: 64px;
    background-color: #fff;



    @media screen and (max-width: $whenSmallScreen) {
        width: 100%;
    }
}

.expandable {
    flex-grow: 1;
}

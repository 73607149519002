.action {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
        margin-right: 10px;
    }
}

.footer {
    justify-content: space-between;
}
